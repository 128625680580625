import React, { useContext, useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import Home from '../components/layout/Home/Home'
import Inicio from '../components/layout/Home/Inicio'
import Login from '../components/layout/Login/Login'
import ListadoUsuarios from '../components/layout/Login/ListadoUsuarios'
import Logout from '../components/layout/Login/Logout'
import AuthContextProvider from "./contexts/AuthContext.js";

// {/* Catalogos */}
import BotonesCatalogos from "./views/Catalogos/BotonesCatalogos"
import ListadoAreas from './views/Catalogos/Areas/ListadoAreas'
import ListadoTerminales from "./views/Catalogos/Terminales/ListadoTerminales.jsx";
import ListadoDestinos from "./views/Catalogos/Destinos/ListadoDestinos.jsx";
import ListadoPaqueterias from './views/Catalogos/Paqueterias/ListadoPaqueterias'
import ListadoProveedores from './views/Catalogos/Proveedores/ListadoProveedores'
import ProveedoresCreate from './views/Catalogos/Proveedores/ProveedoresCreate'
import ListadoDepartamentos from './views/Catalogos/Departamentos/ListadoDepartamentos'
import ListadoGrupos from './views/Catalogos/Grupos/ListadoGrupos'

// {/* Articulos */}
import BotonesArticulos from "./views/Articulos/BotonesArticulos"
import ListadoLineas from './views/Articulos/Lineas/ListadoLineas'
import ListadoMarcas from './views/Articulos/Marcas/ListadoMarcas'
import ListadoFamilias from './views/Articulos/Familias/ListadoFamilias'
import ListadoColecciones from './views/Articulos/Colecciones/ListadoColecciones'
import ListadoArticulos from './views/Articulos/Articulos/ListadoArticulos'
import ListadoArticulosFoto from './views/Articulos/Articulos/ListadoArticulosFoto'
import ArticulosCreate from './views/Articulos/Articulos/ArticulosCreate'
import ArticulosMasivaCreate from './views/Articulos/Articulos/ArticulosMasivaCreate'

// {/* Inventarios */}
import BotonesInventarios from "./views/Inventarios/BotonesInventarios"
import ListadoInventarios from './views/Inventarios/Inventarios/ListadoInventarios'
import ListadoInventariosGeneral from './views/Inventarios/Inventarios/ListadoInventariosGeneral'
import ListadoDisponible from "./views/Inventarios/Inventarios/ListadoDisponible";
import ListadoDisponibleGENERAL from "./views/Inventarios/Inventarios/ListadoDisponibleGENERAL";
import ListadoDisponibleBODEGA from "./views/Inventarios/Inventarios/ListadoDisponibleBODEGA";
import ListadoExistenciaDisponiblePantaco from "./views/Inventarios/Inventarios/ListadoExistenciaDisponiblePantaco";
import ListadoExistenciaDisponiblePantaco83 from "./views/Inventarios/Inventarios/ListadoExistenciaDisponiblePantaco83";
import ListadoExistenciaDisponibleTIM81 from "./views/Inventarios/Inventarios/ListadoExistenciaDisponibleTIM81";
import ListadoExistenciaDisponibleBodega from "./views/Inventarios/Inventarios/ListadoExistenciaDisponibleBodega";
import ListadoDisponiblePantaco from "./views/Inventarios/Inventarios/ListadoDisponiblePantaco";
import ListadoDisponiblePantacos from "./views/Inventarios/Inventarios/ListadoDisponiblePantacos";
import ListadoDisponiblePantaco83 from "./views/Inventarios/Inventarios/ListadoDisponiblePantaco83";
import ListadoKardex from "./views/Inventarios/Inventarios/ListadoKardex";
import ListadoTracking from "./views/Inventarios/Inventarios/ListadoTracking";
import MovimientosRicardo from "./views/Inventarios/Inventarios/MovimientosRicardo";
import ListadoDisponibleVendedor from "./views/Inventarios/Inventarios/ListadoDisponibleVendedor";
import ListadoInventarioPendRecibir from "./views/Inventarios/Inventarios/ListadoInventarioPendRecibir";
import ListadoAjustesInventario from "./views/Inventarios/Ajustes/ListadoAjustesInventario"
import AjustesInventarioCreate from "./views/Inventarios/Ajustes/AjustesInventarioCreate"
import BotonesTraspasos from "./views/Inventarios/BotonesTraspasos"
import CuadrarInventario from "./views/Inventarios/Inventarios/CuadrarInventario";

// Tiendas
import BotonesTiendas from "./views/Tiendas/BotonesTiendas"
import ListadoInventariosTiendas from './views/Tiendas/ListadoInventariosTiendas'
import ListadoVentasTiendas from './views/Tiendas/VentasTiendas/ListadoVentasTiendas'
import VentasTiendasCreate from './views/Tiendas/VentasTiendas/VentasTiendasCreate'
import AbonosTiendasCreate from './views/Tiendas/AbonosTiendas/AbonosTiendasCreate'
import ListadoAbonosTiendas from './views/Tiendas/AbonosTiendas/ListadoAbonosTiendas'
import ListadoGastosTiendas from './views/Tiendas/GastosTiendas/ListadoGastosTiendas'
import GastosTiendasCreate from './views/Tiendas/GastosTiendas/GastosTiendasCreate'
import ListadoDinerosTiendas from "./views/Tiendas/DinerosTiendas/ListadoDinerosTiendas";
import DinerosTiendasCreate from "./views/Tiendas/DinerosTiendas/DinerosTiendasCreate";
import ListadoDevolucionesTiendas from './views/Tiendas/DevolucionesTiendas/ListadoDevolucionesTiendas'
import DevolucionesTiendasCreate from './views/Tiendas/DevolucionesTiendas/DevolucionesTiendasCreate'
import CorteTiendas from "./views/Tiendas/CorteTiendas/CorteTiendas";
import EstadoCuentaTiendas from "./views/Tiendas/EstadoCuentaTiendas/EstadoCuentaTiendas";
import EstadoCuentaTiendasAnalisis from "./views/Tiendas/EstadoCuentaTiendas/EstadoCuentaTiendasAnalisis";
import ListadoVentasTiendasPendientes from "./views/Tiendas/VentasTiendas/ListadoVentasTiendasPendientes";
import ListadoDevolucionesTiendasAdmin from "./views/Tiendas/DevolucionesTiendas/ListadoDevolucionesTiendasAdmin";
import ListadoClientesTiendas from "./views/Tiendas/ClientesTiendas/ListadoClientesTiendas";
import ClientesCreateTiendas from "./views/Tiendas/ClientesTiendas/ClientesCreateTiendas";

// Traspasos
import TraspasosSalidaCreate from './views/Inventarios/Traspasos/TraspasosSalidaCreate'
import ListadoTraspasosSalida from './views/Inventarios/Traspasos/ListadoTraspasosSalida'
import TraspasosEntradaCreate from './views/Inventarios/Traspasos/TraspasosEntradaCreate'
import ListadoTraspasosEntrada from './views/Inventarios/Traspasos/ListadoTraspasosEntrada'
import TraspasosSolicitudCreate from './views/Inventarios/Traspasos/TraspasosSolicitudV2Create'
import ListadoTraspasosSolicitud from './views/Inventarios/Traspasos/ListadoTraspasosSolicitudV2'
import ListadoPendienteTraspasar from './views/Inventarios/Traspasos/ListadoPendienteTraspasar'

// Muestras
import MuestrasSalidaCreate from './views/Inventarios/Muestras/MuestrasSalidaCreate'
import ListadoMuestrasSalida from './views/Inventarios/Muestras/ListadoMuestrasSalida'
import MuestrasEntradaCreate from './views/Inventarios/Muestras/MuestrasEntradaCreate'
import ListadoMuestrasEntrada from './views/Inventarios/Muestras/ListadoMuestrasEntrada'

// {/* Compras */}
import BotonesCompras from "./views/Compras/BotonesCompras"
import ListadoOrdenesCompra from './views/Compras/OrdenesCompra/ListadoOrdenesCompra'
import OrdenesCompraCreate from './views/Compras/OrdenesCompra/OrdenesCompraCreate'
import OrdenesCompraMasivaCreate from './views/Compras/OrdenesCompra/OrdenesCompraMasivaCreate'
import ListadoEmbarques from './views/Compras/Embarques/ListadoEmbarques'
import EmbarquesCreate from './views/Compras/Embarques/EmbarquesCreate'
import ListadoPendEmbarcar from "./views/Compras/Embarques/ListadoPendEmbarcar";
import ListadoCompras from "./views/Compras/Compras/ListadoCompras";
import ComprasCreate from "./views/Compras/Compras/ComprasCreate";
import ComprasDirectasCreate from "./views/Compras/Compras/ComprasDirectasCreate";
import ListadoPendRecibir from "./views/Compras/Compras/ListadoPendRecibir";
import ListadoConceptosGastosEmbarques from "./views/Compras/ConceptosGastosEmbarques/ListadoConceptosGastosEmbarques";
import ListadoControlCalidad from "./views/Compras/Compras/ListadoControlCalidad";

// {/* Ventas */}
import BotonesVentas from "./views/Ventas/BotonesVentas"
import ListadoPedidos from './views/Ventas/Pedidos/ListadoPedidos'
import PedidosCreate from './views/Ventas/Pedidos/PedidosCreate'
import PedidosExpoCreate from './views/Ventas/Pedidos/PedidosExpoCreate'
import ListadoSurtidos from './views/Ventas/Surtidos/ListadoSurtidos'
import ListadoPendienteSurtir from './views/Ventas/Surtidos/ListadoPendienteSurtir'
import ListadoDevoluciones from './views/Ventas/Devoluciones/ListadoDevoluciones'
import DevolucionesCreate from './views/Ventas/Devoluciones/DevolucionesCreate'
import ListadoDepartamentosClientes from './views/Ventas/DepartamentosClientes/ListadoDepartamentosClientes'
import AnalisisArticulosPedidos from "./views/Ventas/Pedidos/AnalisisArticulosPedidos";
import ListoSurtir from "./views/Ventas/Pedidos/ListoSurtir";
import ListoSurtirCliente from "./views/Ventas/Pedidos/ListoSurtirCliente";

// {/* Gastos */}
import BotonesGastos from "./views/ConfigAdmin/BotonesGastos"
import ListadoGastosGeneral from './views/ConfigAdmin/GastosGeneral/ListadoGastosGeneral'
import GastosGeneralCreate from './views/ConfigAdmin/GastosGeneral/GastosGeneralCreate'
import ListadoConceptosGastos from './views/ConfigAdmin/ConceptosGastos/ListadoConceptosGastos'
import ConceptosGastosCreate from './views/ConfigAdmin/ConceptosGastos/ConceptosGastosCreate'


//Nominas
import BotonesNominas from './views/Empleados/BotonesNominas'
import ListadoColaboradores from "./views/Empleados/Colaboradores/ListadoColaboradores"
import ListadoPuestos from "./views/Empleados/Puestos/ListadoPuestos"
import ColaboradoresCreate from "./views/Empleados/Colaboradores/ColaboradoresCreate"

// Clientes
import ListadoClientes from "./views/Clientes/Clientes/ListadoClientes"
import ClientesCreate from "./views/Clientes/Clientes/ClientesCreate"


//Reportes
import BotonesReportes from './views/Reportes/BotonesReportes'
import BotonesReportesV2 from './views/Reportes/BotonesReportesV2'
import GraficaVentasMensual from "./views/Reportes/GraficaVentasMensual";
import GraficaVentasMensualVendedor from "./views/Reportes/GraficaVentasMensualVendedor";
import GraficaVentasDepartamento from "./views/Reportes/GraficaVentasDepartamento";
import GraficaVentasGrupo from "./views/Reportes/GraficaVentasGrupo";
import GraficaVentasVendedor from "./views/Reportes/GraficaVentasVendedor";
import GraficaVentasEstado from "./views/Reportes/GraficaVentasEstado";
import ReporteUltimoPrecio from "./views/Reportes/ReporteUltimoPrecio";
import ReporteUltimoPrecioArticulo from "./views/Reportes/ReporteUltimoPrecioArticulo";
import GraficaVentasMensualCliente from "./views/Reportes/GraficaVentasMensualCliente";
import ValorInventario from './views/Reportes/ValorInventario'
import ListadoSellThru from "./views/Reportes/ListadoSellThru";
import CalificadoraClientes from "./views/Reportes/CalificadoraClientes";
import BotonesReportesVentas from "./views/Reportes/BotonesReportesVentas";
import BotonesReportesAdmin from "./views/Reportes/BotonesReportesAdmin";
import GraficaVentasMensualComparativa from "./views/Reportes/GraficaVentasMensualComparativa";
import GraficaVentasDesgloce from "./views/Reportes/GraficaVentasDesgloce";
import ReporteResumenAbonosTiendas from "./views/Reportes/ReporteResumenAbonosTiendas";
import ReporteResumenVentasTiendas from "./views/Reportes/ReporteResumenVentasTiendas";
import TablaVentasMensualComparativa from "./views/Reportes/TablaVentasMensualComparativa";
import TablaAbonosMensualComparativa from "./views/Reportes/TablaAbonosMensualComparativa";
import TablaAbonosMensualComparativaAlmacen from "./views/Reportes/TablaAbonosMensualComparativaAlmacen.jsx";
import TablaVentasMensualComparativaCliente from "./views/Reportes/TablaVentasMensualComparativaCliente";
import TablaVentasMensualComparativaVendedor from "./views/Reportes/TablaVentasMensualComparativaVendedor";
import TablaVentasArticulos from "./views/Reportes/TablaVentasArticulos";

// Dashboards
import BotonesDashboards from "./views/Reportes/BotonesDashboards";
import DashboardVentas from "./views/Reportes/DashboardVentas";
import DashboardInventarios from "./views/Reportes/DashboardInventarios";
import Balance from "./views/Reportes/Balance";
import Locker from "./views/Reportes/Locker.jsx";

// Logs
import Logs from './views/Logs/ListadoLogs'

// Admin
import BotonesAdmin from "./views/Admin/BotonesAdmin";
import ListadoBancos from "./views/Admin/Bancos/ListadoBancos";
import AnticiposCreate from './views/Admin/Anticipos/AnticiposCreate'
import ListadoAnticipos from './views/Admin/Anticipos/ListadoAnticipos'
import AbonosCreate from './views/Admin/Abonos/AbonosCreate'
import ListadoAbonos from './views/Admin/Abonos/ListadoAbonos'
import NotasCargoCreate from './views/Admin/NotasCargo/NotasCargoCreate'
import ListadoNotasCargo from './views/Admin/NotasCargo/ListadoNotasCargo'
import ListadoDevolucionesAdmin from './views/Admin/DevolucionesAdmin/ListadoDevolucionesAdmin'
import EstadoCuenta from "./views/Admin/EstadoCuenta/EstadoCuenta";
import ComisionesCreate from "./views/Admin/Comisiones/ComisionesCreate";
import ListadoComisiones from "./views/Admin/Comisiones/ListadoComisiones";
import EstadoCuentaColaboradores from "./views/Admin/Comisiones/EstadoCuentaColaboradores";
import ListadoVentas from "./views/Admin/Ventas/ListadoVentas";

// CXP
import BotonesCXP from "./views/CXP/BotonesCXP";
import EstadoCuentaCXP from "./views/CXP/EstadoCuentaCXP/EstadoCuentaCXP";
import ListadoComprasCXP from "./views/CXP/ComprasCXP/ListadoComprasCXP";
import AbonosProveedoresCreate from './views/CXP/AbonosProveedores/AbonosProveedoresCreate'
import ListadoAbonosProveedores from './views/CXP/AbonosProveedores/ListadoAbonosProveedores'

// Whastapp
import Whatsapp from "./views/WhatsApp/Whatsapp";


function Routes() {
    return (
        <AuthContextProvider>
            <BrowserRouter forceRefresh={true}>
            <Switch>
                <Route exact path ="/" component={Home} />
                <Route exact path ="/Inicio" component={Inicio} />
                <Route exact path ="/Login" component={Login} />
                <Route exact path ="/Logout" component={Logout} />
                <Route exact path ="/MenuUsuarios" component={ListadoUsuarios} />
                {/* Catalogos */}
                <Route exact path ="/MenuCatalogos" component={BotonesCatalogos} />
                <Route exact path ="/Areas" component={ListadoAreas} />
                <Route exact path ="/Terminales" component={ListadoTerminales} />
                <Route exact path ="/Destinos" component={ListadoDestinos} />
                <Route exact path ="/Paqueterias" component={ListadoPaqueterias} />
                <Route exact path ="/Departamentos" component={ListadoDepartamentos} />
                <Route exact path ="/Proveedores" component={ListadoProveedores} />
                {/* Articulos */}
                <Route exact path ="/MenuArticulos" component={BotonesArticulos} />
                <Route exact path ="/Lineas" component={ListadoLineas} />
                <Route exact path ="/Marcas" component={ListadoMarcas} />
                <Route exact path ="/Familias" component={ListadoFamilias} />
                <Route exact path ="/Colecciones" component={ListadoColecciones} />
                <Route exact path ="/ListadoArticulos" component={ListadoArticulos} />
                <Route exact path ="/ListadoArticulosFoto" component={ListadoArticulosFoto} />
                <Route exact path ="/ArticulosCreate" component={ArticulosCreate} />
                <Route exact path ="/ArticulosMasivaCreate" component={ArticulosMasivaCreate} />
                {/* Inventarios */}
                <Route exact path ="/MenuInventarios" component={BotonesInventarios} />
                <Route exact path ="/ListadoInventarios" component={ListadoInventarios} />
                <Route exact path ="/ListadoInventariosGeneral" component={ListadoInventariosGeneral} />
                <Route exact path ="/ListadoDisponible" component={ListadoDisponible} />
                <Route exact path ="/ListadoDisponibleGENERAL" component={ListadoDisponibleGENERAL} />
                <Route exact path ="/ListadoDisponibleBODEGA" component={ListadoDisponibleBODEGA} />
                <Route exact path ="/ListadoDisponiblePantaco" component={ListadoDisponiblePantaco} />
                <Route exact path ="/ListadoDisponiblePantacos" component={ListadoDisponiblePantacos} />
                <Route exact path ="/ListadoDisponiblePantaco83" component={ListadoDisponiblePantaco83} />
                <Route exact path ="/ListadoExistenciaDisponiblePantaco" component={ListadoExistenciaDisponiblePantaco} />
                <Route exact path ="/ListadoExistenciaDisponiblePantaco83" component={ListadoExistenciaDisponiblePantaco83} />
                <Route exact path ="/ListadoExistenciaDisponibleTIM81" component={ListadoExistenciaDisponibleTIM81} />
                <Route exact path ="/ListadoExistenciaDisponibleBodega" component={ListadoExistenciaDisponibleBodega} />
                <Route exact path ="/ListadoDisponibleVendedor" component={ListadoDisponibleVendedor} />
                <Route exact path ="/ListadoInventarioPendRecibir" component={ListadoInventarioPendRecibir} />
                <Route exact path ="/ListadoKardex" component={ListadoKardex} />
                <Route exact path ="/ListadoTracking" component={ListadoTracking} />
                <Route exact path ="/MovimientosRicardo" component={MovimientosRicardo} />
                <Route exact path ="/ListadoAjustesInventario" component={ListadoAjustesInventario} />
                <Route exact path ="/AjustesInventarioCreate" component={AjustesInventarioCreate} />
                <Route exact path ="/MenuTraspasos" component={BotonesTraspasos} />
                <Route exact path ="/CuadrarInventario" component={CuadrarInventario} />
                {/* Tiendas */}
                <Route exact path ="/MenuTiendas" component={BotonesTiendas} />
                <Route exact path ="/ListadoInventariosTiendas" component={ListadoInventariosTiendas} />  
                <Route exact path ="/ListadoVentasTiendas" component={ListadoVentasTiendas} />       
                <Route exact path ="/ListadoVentasTiendasPendientes" component={ListadoVentasTiendasPendientes} />             
                <Route exact path ="/VentasTiendasCreate" component={VentasTiendasCreate} />               
                <Route exact path ="/AbonosTiendasCreate" component={AbonosTiendasCreate} />  
                <Route exact path ="/ListadoAbonosTiendas" component={ListadoAbonosTiendas} />
                <Route exact path ="/GastosTiendas" component={ListadoGastosTiendas} />
                <Route exact path ="/GastosTiendasCreate" component={GastosTiendasCreate} />  
                <Route exact path ="/DinerosTiendas" component={ListadoDinerosTiendas} />
                <Route exact path ="/DinerosTiendasCreate" component={DinerosTiendasCreate} />  
                <Route exact path ="/ListadoDevolucionesTiendas" component={ListadoDevolucionesTiendas} />
                <Route exact path ="/DevolucionesTiendasCreate" component={DevolucionesTiendasCreate} />  
                <Route exact path ="/CorteTiendas" component={CorteTiendas} />    
                <Route exact path ="/EstadoCuentaTiendas" component={EstadoCuentaTiendas} />  
                <Route exact path ="/EstadoCuentaTiendasAnalisis" component={EstadoCuentaTiendasAnalisis} />  
                <Route exact path ="/ListadoDevolucionesTiendasAdmin" component={ListadoDevolucionesTiendasAdmin} />
                <Route exact path ="/ListadoClientesTiendas" component={ListadoClientesTiendas} />
                <Route exact path ="/ClientesCreateTiendas" component={ClientesCreateTiendas} />

                {/* Traspasos */}
                <Route exact path ="/TraspasosSalidaCreate" component={TraspasosSalidaCreate} />
                <Route exact path ="/ListadoTraspasosSalida" component={ListadoTraspasosSalida} />
                <Route exact path ="/TraspasosEntradaCreate" component={TraspasosEntradaCreate} />
                <Route exact path ="/ListadoTraspasosEntrada" component={ListadoTraspasosEntrada} />
                <Route exact path ="/TraspasosSolicitudCreate" component={TraspasosSolicitudCreate} />
                <Route exact path ="/ListadoTraspasosSolicitud" component={ListadoTraspasosSolicitud} />
                <Route exact path ="/ListadoPendienteTraspasar" component={ListadoPendienteTraspasar} />
                {/* Muestras */}
                <Route exact path ="/MuestrasSalidaCreate" component={MuestrasSalidaCreate} />
                <Route exact path ="/ListadoMuestrasSalida" component={ListadoMuestrasSalida} />
                <Route exact path ="/MuestrasEntradaCreate" component={MuestrasEntradaCreate} />
                <Route exact path ="/ListadoMuestrasEntrada" component={ListadoMuestrasEntrada} />
                {/* Compras */}
                <Route exact path ="/MenuCompras" component={BotonesCompras} />
                <Route exact path ="/ListadoOrdenesCompra" component={ListadoOrdenesCompra} />
                <Route exact path ="/OrdenesCompraCreate" component={OrdenesCompraCreate} />
                <Route exact path ="/OrdenesCompraMasivaCreate" component={OrdenesCompraMasivaCreate} />
                <Route exact path ="/ListadoEmbarques" component={ListadoEmbarques} />
                <Route exact path ="/EmbarquesCreate" component={EmbarquesCreate} />
                <Route exact path ="/ListadoPendEmbarcar" component={ListadoPendEmbarcar} />   
                <Route exact path ="/ListadoCompras" component={ListadoCompras} />
                <Route exact path ="/ComprasCreate" component={ComprasCreate} />
                <Route exact path ="/ComprasDirectasCreate" component={ComprasDirectasCreate} />
                <Route exact path ="/ListadoPendRecibir" component={ListadoPendRecibir} />  
                <Route exact path ="/ConceptosGastosEmbarques" component={ListadoConceptosGastosEmbarques} />  
                <Route exact path ="/ListadoControlCalidad" component={ListadoControlCalidad} />
                {/* Ventas */}
                <Route exact path ="/MenuVentas" component={BotonesVentas} />
                <Route exact path ="/ListadoPedidos" component={ListadoPedidos} />
                <Route exact path ="/PedidosCreate" component={PedidosCreate} />
                <Route exact path ="/PedidosExpoCreate" component={PedidosExpoCreate} />
                <Route exact path ="/ListadoSurtidos" component={ListadoSurtidos} />
                <Route exact path ="/ListadoPendienteSurtir" component={ListadoPendienteSurtir} />
                <Route exact path ="/ListadoDevoluciones" component={ListadoDevoluciones} />
                <Route exact path ="/DevolucionesCreate" component={DevolucionesCreate} />
                <Route exact path ="/DepartamentosClientes" component={ListadoDepartamentosClientes} />
                <Route exact path ="/AnalisisArticulosPedidos" component={AnalisisArticulosPedidos} />
                <Route exact path ="/ListoSurtir" component={ListoSurtir} />
                <Route exact path ="/ListoSurtirCliente" component={ListoSurtirCliente} />
                {/* Gastos */}
                <Route exact path ="/MenuGastos" component={BotonesGastos} />
                <Route exact path ="/MenuNominas" component={BotonesNominas} />
                <Route exact path ="/Empleados" component={ListadoColaboradores} />
                <Route exact path ="/EmpleadosCreate" component={ColaboradoresCreate} />
                <Route exact path ="/Puestos" component={ListadoPuestos} />
                <Route exact path ="/ProveedoresCreate" component={ProveedoresCreate} />
                <Route exact path ="/Gastos" component={ListadoGastosGeneral} />
                <Route exact path ="/GastosCreate" component={GastosGeneralCreate} />
                <Route exact path ="/ConceptosGastos" component={ListadoConceptosGastos} />
                <Route exact path ="/conceptosGastosCreate" component={ConceptosGastosCreate} />
                {/* Clientes */}
                <Route exact path ="/Clientes" component={ListadoClientes} />
                <Route exact path ="/ClientesCreate" component={ClientesCreate} />
                <Route exact path ="/Grupos" component={ListadoGrupos} />
                {/* Reportes */}
                <Route exact path ="/MenuReportes" component={BotonesReportes} />
                <Route exact path ="/MenuReportesV2" component={BotonesReportesV2} />
                <Route exact path ="/MenuReportesAdmin" component={BotonesReportesAdmin} />
                <Route exact path ="/MenuReportesVentas" component={BotonesReportesVentas} />
                <Route exact path ="/GraficaVentasMensualComparativa" component={GraficaVentasMensualComparativa} />
                <Route exact path ="/TablaVentasArticulos" component={TablaVentasArticulos} />
                <Route exact path ="/TablaVentasMensualComparativa" component={TablaVentasMensualComparativa} />
                <Route exact path ="/TablaAbonosMensualComparativa" component={TablaAbonosMensualComparativa} />
                <Route exact path ="/TablaAbonosMensualComparativaAlmacen" component={TablaAbonosMensualComparativaAlmacen} />
                <Route exact path ="/TablaVentasMensualComparativaCliente" component={TablaVentasMensualComparativaCliente} />
                <Route exact path ="/TablaVentasMensualComparativaVendedor" component={TablaVentasMensualComparativaVendedor} />
                <Route exact path ="/GraficaVentasMensualCliente" component={GraficaVentasMensualCliente} />
                <Route exact path ="/GraficaVentasMensualVendedor" component={GraficaVentasMensualVendedor} />
                <Route exact path ="/GraficaVentasDesgloce" component={GraficaVentasDesgloce} />
                <Route exact path ="/GraficaVentasDepartamento" component={GraficaVentasDepartamento} />
                <Route exact path ="/GraficaVentasGrupo" component={GraficaVentasGrupo} />
                <Route exact path ="/GraficaVentasVendedor" component={GraficaVentasVendedor} />
                <Route exact path ="/GraficaVentasEstado" component={GraficaVentasEstado} />
                <Route exact path ="/ReporteUltimoPrecio" component={ReporteUltimoPrecio} />
                <Route exact path ="/ReporteUltimoPrecioArticulo" component={ReporteUltimoPrecioArticulo} />
                <Route exact path ="/ValorInventario" component={ValorInventario} />
                <Route exact path ="/SellThru" component={ListadoSellThru} />
                <Route exact path ="/CalificadoraClientes" component={CalificadoraClientes} />
                <Route exact path ="/ReporteResumenAbonosTiendas" component={ReporteResumenAbonosTiendas} />
                <Route exact path ="/ReporteResumenVentasTiendas" component={ReporteResumenVentasTiendas} />

                {/* Dashboards */}
                <Route exact path ="/Dashboards" component={BotonesDashboards} />
                <Route exact path ="/DashboardVentas" component={DashboardVentas} />
                <Route exact path ="/DashboardInventarios" component={DashboardInventarios} />
                <Route exact path ="/Balance" component={Balance} />
                <Route exact path ="/Locker" component={Locker} />
                
                {/* Logs  */}
                <Route exact path ="/MenuLogs" component={Logs} />
                {/* Admin */}
                <Route exact path ="/MenuAdmin" component={BotonesAdmin} />
                <Route exact path ="/Bancos" component={ListadoBancos} />
                <Route exact path ="/AnticiposCreate" component={AnticiposCreate} />
                <Route exact path ="/ListadoAnticipos" component={ListadoAnticipos} />
                <Route exact path ="/AbonosCreate" component={AbonosCreate} />
                <Route exact path ="/ListadoAbonos" component={ListadoAbonos} />
                <Route exact path ="/NotasCargoCreate" component={NotasCargoCreate} />
                <Route exact path ="/ListadoNotasCargo" component={ListadoNotasCargo} />
                <Route exact path ="/ListadoDevolucionesAdmin" component={ListadoDevolucionesAdmin} />
                <Route exact path ="/EstadoCuenta" component={EstadoCuenta} />
                <Route exact path ="/ComisionesCreate" component={ComisionesCreate} />
                <Route exact path ="/ListadoComisiones" component={ListadoComisiones} />
                <Route exact path ="/ListadoVentas" component={ListadoVentas} />
                <Route exact path ="/EstadoCuentaVendedores" component={EstadoCuentaColaboradores} />
                {/* CXP */}
                <Route exact path ="/MenuCXP" component={BotonesCXP} />
                <Route exact path ="/EstadoCuentaCXP" component={EstadoCuentaCXP} />
                <Route exact path ="/ListadoComprasCXP" component={ListadoComprasCXP} />
                <Route exact path ="/AbonosProveedoresCreate" component={AbonosProveedoresCreate} />
                <Route exact path ="/ListadoAbonosProveedores" component={ListadoAbonosProveedores} />
                {/* Whatsapp */}
                <Route exact path ="/Whatsapp" component={Whatsapp} />
            </Switch>
            </BrowserRouter>
            </AuthContextProvider>

    )
}

export default Routes
