import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";

import {
  Button,
  Row,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
  Form,
  FormGroup,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";

function DinerosTiendasCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_DINEROS_TIENDAS = process.env.REACT_APP_URL_DINEROS_TIENDAS;
  const URL_TERMINALES = process.env.REACT_APP_URL_TERMINALES;
  const URL_DESTINOS = process.env.REACT_APP_URL_DESTINOS;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [terminales, setTerminales] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [text, setText] = useState(false);
  const [fecha, setFecha] = useState(hoy);
  const [efectivo, setEfectivo] = useState(0);
  const [tdc, setTDC] = useState(0);
  const [transfer, setTransfer] = useState(0);
  const [fechaTransfer, setFechaTransfer] = useState("NA");
  const [selectedDestino, setSelectedDestino] = useState("674c921d1d49acba5be4cc7f");
  const [selectedTerminal, setSelectedTerminal] = useState("674c92131d49acba5be4cc78");
  const [validaBoton, setValidaBoton] = useState(true);
  const [total, setTotal] = useState(0);
  const [observaciones, setObservaciones] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);


  useEffect(() => {

    axios
    .get(`${URL_TERMINALES}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allTerminales = response.data;
      setTerminales(allTerminales);
    })
    .catch((err) => {
      console.log(err);
    });

    axios
    .get(`${URL_DESTINOS}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allDestinos = response.data;
      setDestinos(allDestinos);
    })
    .catch((err) => {
      console.log(err);
    });

  }, []);

  useMemo(()=>{
    let temp = parseFloat(efectivo) + parseFloat(tdc) + parseFloat(transfer)
    setTotal(temp)
  },[efectivo, tdc, transfer, total])

  function savePago() {
    setValidaBoton(false);
    Swal.fire({
      title: "Estas seguro?",
      text: "Se registrará la entrada",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
    }).then((result) => {
      if (result.isConfirmed) {
        toggleProgreso();
            axios
              .post(
                URL_DINEROS_TIENDAS,
                {
                  fecha,
                  total,
                  areas: user.areas,
                  efectivo,
                  tdc,
                  transfer,
                  fechaTransfer,
                  cortesia:0,
                  nomina:0,
                  destinos: selectedDestino,
                  terminales: selectedTerminal,
                  observaciones,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then((data) => {
                axios
                .post(
                  URL_LOGS,
                  {
                    tipo:'Crear Entrada Dinero Tienda',
                    detalle: `Fecha: ${fecha} / Total: ${total} `,
                    user: user.id
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
              
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                
              })
              .catch((error) => {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Something went wrong!",
                  footer: `${error.response.data}`,
                });
                console.log(error);
              });
         
      }
    });
  }



  return (
    <>
      <Header />
      <br />
      <br />
      {user.abonosTiendas ? (
        
      <div className="container">
      <div className="card container col-sm-8">
          <h3 align="center">Captura una Entrada de Dinero</h3>
          <Form>
            <Row>
            <Col md={3}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      value={fecha}
                      required
                      onChange={(e) => {
                        setFecha(e.target.value);
                      }}
                    />
                  </Col>
            </Row>
           <Row>
           <Col md={3}>
                  <Label>Efectivo</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={efectivo}
                    required
                    onChange={(e) => {
                      setEfectivo(e.target.value);
                    }}
                  />
                </Col>
           <Col md={3}>
                    <Label>TDC</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={tdc}
                      required
                      onChange={(e) => {
                        setTDC(e.target.value);
                      }}
                    />
                  </Col>

                    <Col md={3}>
                      <Label>Terminal</Label>
                      <Input
                        type="select"
                        value={selectedTerminal}
                        required
                        onChange={(e) => {
                          setSelectedTerminal(e.target.value);
                        }}
                        disabled = {tdc > 0 ? false : true}
                      >
                       {terminales.map((a) => {
                              if (a.is_active == "Si") {
                                return <option value={a._id}>{a.name}</option>;
                              }
                            })}
                       </Input>
                    </Col>
                 
              </Row>
                <Row>
              <Col md={3}>
                    <Label>Transfer</Label>
                    <Input
                      type="number"
                      min="0"
                      step="any"
                      value={transfer}
                      required
                      onChange={(e) => {
                        setTransfer(e.target.value);
                      }}
                    />
                  </Col>
                    <Col md={3}>
                      <Label>Destino</Label>
                      <Input
                        type="select"
                        value={selectedDestino}
                        required
                        onChange={(e) => {
                          setSelectedDestino(e.target.value);
                        }}
                        disabled = {transfer > 0 ? false : true}
                      >
                       {destinos.map((a) => {
                          if (a.is_active == "Si") {
                            return <option value={a._id}>{a.name}</option>;
                          }
                        })}
                      </Input>
                    </Col>
                    <Col md={3}>
                    <Label>Fecha</Label>
                    <Input
                      type="date"
                      value={fechaTransfer}
                      required
                      onChange={(e) => {
                        setFechaTransfer(e.target.value);
                      }}
                      disabled = {transfer > 0 || tdc > 0 ? false : true}
                    />
                  </Col>
                   
           </Row>
           <Row>
           <Col md={6}>
                    <Label>Observaciones</Label>
                    <Input
                      type="text"
                      value={observaciones}
                      required
                      onChange={(e) => {
                        setObservaciones(e.target.value);
                      }}
                    />
                  </Col>
           <Col md={3}>
                  <Label>Total</Label>
                  <Input
                    type="number"
                    min="0"
                    step="any"
                    value={total}
                    required
                    disabled
                  />
                </Col>
           </Row>
            <br />
            <Row>
              <Button className="btn btn-success" onClick={savePago}>
                {" "}
                Registrar
              </Button>

              <Button
                href="/DinerosTiendas"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Row>
          </Form>
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios segundos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

        </div>
        </div>
      ) : undefined}
      
    </>
  );
}

export default DinerosTiendasCreate;
