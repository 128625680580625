import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import CurrencyInput from "react-currency-input-field";
import { formatValue } from "react-currency-input-field";
import { makeStyles } from "@material-ui/core/styles";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function PedidosExpoCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_INVENTARIOS = process.env.REACT_APP_URL_INVENTARIOS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const AREAS_MATRIZ = process.env.REACT_APP_AREAS_MATRIZ;
  const AREAS_BODEGA = process.env.REACT_APP_AREAS_BODEGA;
  const AREAS_PANTACO = process.env.REACT_APP_AREAS_PANTACO;
  const AREAS_ELI = process.env.REACT_APP_AREAS_ELI;
  const AREAS_MAGALI = process.env.REACT_APP_AREAS_MAGALI;
  const AREAS_ROLANDO = process.env.REACT_APP_AREAS_ROLANDO;
  const PASSWORD_CAMBIA_PRECIO = process.env.REACT_APP_PASSWORD_CAMBIA_PRECIO;
  const URL_PAQUETERIAS = process.env.REACT_APP_URL_PAQUETERIAS;
  const URL_SEND_MAIL = process.env.REACT_APP_URL_SEND_MAIL;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_BANCOS = process.env.REACT_APP_URL_BANCOS;
  const URL_ANTICIPOS = process.env.REACT_APP_URL_ANTICIPOS;
  const URL_GRUPOS = process.env.REACT_APP_URL_GRUPOS;
  const URL_DEPARTAMENTOS_CLIENTES =
    process.env.REACT_APP_URL_DEPARTAMENTOS_CLIENTES;

  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");

  const [articulos, setArticulos] = useState([]);
  const [idsArticulos, setIdsArticulos] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [selectedColaboradorName, setSelectedColaboradorName] = useState("");
  const [selectedColaboradorTelefono, setSelectedColaboradorTelefono] = useState("");
  const [value, setValue] = useState("");

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [inventarios, setInventarios] = useState([]);

  const [clienteNombre, setClienteNombre] = useState("");
  const [cliente, setCliente] = useState("");
  const [departamentoCliente, setDepartamentoCliente] = useState("");
  const [paqueterias, setPaqueterias] = useState([]);
  const [paqueteria, setPaqueteria] = useState("");
  const [idPaqueteria, setIdPaqueteria] = useState("647e2090513ca00014b4edbc");
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [comisionTDC, setComisionTDC] = useState(0);
  const [comisionFlete, setComisionFlete] = useState(0);

  const [fecha, setFecha] = useState(hoy);
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [iva, setIva] = useState(0);
  const [total_cantidad, setTotalCantidad] = useState(0);
  const [selectedImpuesto, setSelectedImpuesto] = useState(0);

  const [descuento, setDescuento] = useState(0);
  const [descuentoNumero, setDescuentoNumero] = useState(0);

  const [vistaAnticipo, setVistaAnticipo] = useState(false);
  const [importe, setImporte] = useState(0);
  const [formaPago, setFormaPago] = useState("");
  const [bancos, setBancos] = useState([]);
  const [selectedBanco, setSelectedBanco] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      piezasPorCaja: 0,
      cajas: 0,
      cantidad: 0,
      precio: 0,
      precioOriginal: 0,
      total: 0,
      articulosNombre: "",
      observacionesArticulo: "NA",
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);
  const [cambiarPrecio, setCambiarPrecio] = useState(false);

  const [modalCambiaPrecio, setModalCambiaPrecio] = useState(false);
  const toggleCambiaPrecio = () => setModalCambiaPrecio(!modalCambiaPrecio);
  const [password, setPassword] = useState("");

  const [mailAutEnviado, setMailAutEnviado] = useState(false);
  const [randomCambiaPrecio, setRandomCambiaPrecio] = useState(
    (Math.random() + 1).toString(36).substring(2)
  );

  // Crear Clientes
  const [vistaCliente, setVistaCliente] = useState(false);
  const [nombre_comercial, setNombreComercial] = useState("");
  const [razon_social, setRazonSocial] = useState("NA");
  const [RFC, setRFC] = useState("NA");
  const [calle, setCalle] = useState("NA");
  const [numero_ext, setNumeroExt] = useState("NA");
  const [numero_int, setNumeroInt] = useState("NA");
  const [delegacion, setDelegacion] = useState("NA");
  const [estado, setEstado] = useState("NA");
  const [pais, setPais] = useState("Mexico");
  const [cp, setCP] = useState(0);
  const [colonia, setColonia] = useState("NA");
  const [ciudad, setCiudad] = useState("NA");
  const [plazo, setPlazo] = useState(0);
  const [regFiscal, setRegFiscal] = useState("NA");
  const [telefono, setTelefono] = useState("1");
  const [email, setEmail] = useState("a@a.a");
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState("");
  const [departamentos, setDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState(
    "646c223d9782be10a8a34959"
  );
  const [selectedPaqueteria, setSelectedPaqueteria] = useState(
    "647e2090513ca00014b4edbc"
  );
  const [selectedAreaCliente, setSelectedAreaCliente] = useState("");
  const [selectedVendedorInput, setSelectedVendedorInput] = useState("");
  const [selectedVendedor, setSelectedVendedor] = useState("");
  const [observacionesCliente, setObservacionesCliente] = useState("NA");
  const [forma_pago, setFormaPagoCliente] = useState("Contado");
  const [descuentoCliente, setDescuentoCliente] = useState(0);

  useMemo(() => {
    axios
      .get(`${URL_ARTICULOS}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
        let result = allArticulos.map((a) => a._id);
        setIdsArticulos(result);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_BANCOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allBancos = response.data;
        setBancos(allBancos);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_PAQUETERIAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allPaqueterias = res.data;
        setPaqueterias(allPaqueterias);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allAreas = res.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_GRUPOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allGrupos = res.data;
        setGrupos(allGrupos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_DEPARTAMENTOS_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allDepartamentos = res.data;
        setDepartamentos(allDepartamentos);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${URL_INVENTARIOS}DisponibleGENERAL`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allDisponible = response.data;
        setInventarios(allDisponible);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedArea]);

  const savePedido = async (event) => {
    event.preventDefault();

    const preciosValidos =
      cambiarPrecio ||
      user.precio_menor ||
      inputFields.every((a) => a.precio >= a.precioOriginal - 51);

    if (!preciosValidos) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No puedes vender $50 menos del precio original",
        showConfirmButton: false,
      });
      return;
    }

    if ((!selectedCliente && !vistaCliente) || !selectedArea) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Faltan datos, favor de revisar",
      });
      return;
    }

    setValidaBoton(false);
    toggleProgreso();

    try {
      let paqueteriaId = idPaqueteria;
      let clienteId = selectedCliente;
      let depId = departamentoCliente;

      if (vistaCliente) {
        const clienteData = {
          nombre_comercial,
          razon_social,
          RFC,
          calle,
          numero_ext,
          numero_int,
          delegacion,
          estado,
          pais,
          cp,
          colonia,
          ciudad,
          telefono,
          email,
          grupos: selectedGrupo,
          departamentosClientes: selectedDepartamento,
          vendedor: selectedVendedor,
          observaciones: observacionesCliente,
          ultimo_pago: "2000-01-01",
          forma_pago,
          areas: selectedAreaCliente,
          plazo,
          regFiscal,
          cargos: 0,
          abonos: 0,
          saldo: 0,
          descuento: descuentoCliente,
          paqueterias: selectedPaqueteria,
          alertas: "Si",
        };

        // Crear cliente
        const clienteResponse = await axios.post(URL_CLIENTES, clienteData, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        });

        if (!clienteResponse.data._id) {
          throw new Error("No se pudo obtener el ID del cliente.");
        }

        clienteId = clienteResponse.data._id;
        paqueteriaId = selectedPaqueteria;
        depId = selectedDepartamento;
      }

      const pedidoResponse = await axios.post(
        URL_PEDIDOS,
        {
          fecha,
          clientes: clienteId,
          departamentosClientes: depId,
          colaboradores: selectedColaborador,
          total_general: parseFloat(total_general).toFixed(2),
          observaciones,
          total_cantidad,
          paqueterias: paqueteriaId,
          areas: selectedArea,
          comisionTDC,
          comisionFlete,
          subTotal: parseFloat(subTotal).toFixed(2),
          iva: parseFloat(iva).toFixed(2),
          impuestos: selectedImpuesto,
          surtido: "No",
          autorizadoApartado: "Si",
          autorizadoSurtido: "No",
          editado: "No",
          fechaModificado: fecha,
          creado: user.id,
          fecha_estimada: fecha,
          fecha_entrega: fecha,
          descuento,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );

      const pedidoId = pedidoResponse.data._id;
      const numeroPedido = pedidoResponse.data.idPedido;

      await Promise.all(
        inputFields.map(async (a) => {
          if (a.articulos && a.cantidad) {
            await axios.post(
              URL_ARTICULOS_PEDIDO,
              {
                pedidos: pedidoId,
                articulos: a.articulos,
                clientes: clienteId,
                cantidad: parseFloat(a.cantidad),
                surtido: 0,
                pendiente_surtir: parseFloat(a.cantidad),
                precio: parseFloat(a.precio).toFixed(2),
                total: parseFloat(a.total).toFixed(2),
                pack: "NA",
                autorizadoSurtido: "No",
                autorizadoApartado: "Si",
                observaciones: a.observacionesArticulo,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
          }
        })
      );

      if (vistaAnticipo) {
        await axios.post(
          URL_ANTICIPOS,
          {
            fecha,
            importe,
            formaPago,
            bancos: selectedBanco,
            clientes: clienteId,
            utilizado: 0,
            saldo: importe,
            pedidos: pedidoId,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
      }

      await axios.post(
        URL_LOGS,
        {
          tipo: "Crear Pedido",
          detalle: `${selectedCliente} ${total_general}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );

      PDFOC(numeroPedido);
      Swal.fire("Good job!", "Creado con éxito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Algo salió mal!",
        footer: error.response?.data || "",
      });
      console.error(error);
      setValidaBoton(true);
    }
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: "",
        piezasPorCaja: 0,
        cajas: 0,
        cantidad: 0,
        precio: 0,
        precioOriginal: 0,
        total: 0,
        articulosNombre: "",
        observacionesArticulo: "NA",
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let totalCan = values.map((c) => parseFloat(c.cantidad));
    let TC = totalCan.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TC);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * TG) / 100 +
        TG +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  };

  const handleChangeInputObservaciones = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.observacionesArticulo = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputPrecio = (value, name, id) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[name] = value;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCajas = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cajas = event.target.value;
        i.cantidad =
          parseFloat(event.target.value) * parseFloat(i.piezasPorCaja);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  const handleChangeInputCantidad = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.cantidad = event.target.value;
        i.cajas = (
          parseFloat(event.target.value) / parseFloat(i.piezasPorCaja)
        ).toFixed(2);
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
  };

  function BuscaArticulo(id, selectedArticulo) {
    if (
      inputFields.filter((e) => e.articulos == selectedArticulo._id).length > 0
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El modelo ya fue capturado!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulosNombre = "";
          i.articulos = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    } else {
      articulos.map((a) => {
        if (a._id == selectedArticulo._id) {
          let idArticulo = selectedArticulo._id;
          let piezasPorCaja = a.piezasPorCaja;
          let precio = a.venta;
          let precioOriginal = a.venta;
          let articulosNombre = selectedArticulo.codigo;
          handleChangeInputArticulo(
            id,
            idArticulo,
            piezasPorCaja,
            precio,
            precioOriginal,
            articulosNombre
          );
        }
      });
    }
  }

  function BuscaCodigo(id, event) {
    if (event.target.value.length >= 24) {
      if (
        inputFields.filter((e) => e.articulos == event.target.value).length > 0
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El modelo ya fue capturado!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.articulosNombre = "";
            i.articulos = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      } else {
        if (idsArticulos.includes(event.target.value)) {
          articulos.map((a) => {
            if (a._id == event.target.value) {
              let idArticulo = a._id;
              let piezasPorCaja = a.piezasPorCaja;
              let precio = a.venta;
              let precioOriginal = a.venta;
              let articulosNombre = a.codigo;
              handleChangeInputArticulo(
                id,
                idArticulo,
                piezasPorCaja,
                precio,
                precioOriginal,
                articulosNombre
              );
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "El modelo no aparece en tu catalogo",
            showConfirmButton: false,
          });
          const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
              i.articulosNombre = "";
              i.articulos = "";
            }
            return i;
          });
          setInputFields(newInputFields);
        }
      }
    } else {
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.articulos = event.target.value;
        }
        return i;
      });
      setInputFields(newInputFields);
    }
  }

  const handleChangeInputArticulo = (
    id,
    idArticulo,
    piezasPorCaja,
    precio,
    precioOriginal,
    articulosNombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.piezasPorCaja = piezasPorCaja;
        i.precio = precio;
        let totalParcial = i.cantidad * i.precio;
        i.total = totalParcial;
        i.articulosNombre = articulosNombre;
        i.precioOriginal = precioOriginal;
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales();
    handleAddFields();
  };

  function ActualizaTotales() {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalCantidad(TK);
    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setSubTotal(TG);

    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * TG) / 100);
    } else {
      setIva(0);
    }
    let temp1 = (TG * descuento) / 100;

    setDescuentoNumero(temp1);

    let totTemp =
      (selectedImpuesto * TG) / 100 +
      TG +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) -
      parseFloat(temp1);

    setTotalGeneral(totTemp);
  }

  function calculaDescuento(e) {
    setDescuento(e);
    let temp1 = (subTotal * e) / 100;

    setDescuentoNumero(temp1);

    let temp = subTotal - temp1;

    let totTemp =
      temp +
      parseFloat(comisionTDC) +
      parseFloat(comisionFlete) +
      parseFloat(iva);

    setTotalGeneral(totTemp);
  }

  const options = articulos.map((option) => {
    const junta = option.codigo;
    const firstLetter = option.codigo[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  const options2 = clientes.map((option) => {
    const junta = option.nombre_comercial + " " + option.codigo;
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      junta,
    };
  });

  function jalaCliente(cliente) {
    setValue(cliente);
    setSelectedCliente(cliente._id);

    axios
      .get(`${URL_CLIENTES}/${cliente._id}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allCliente = res.data;
        setCliente(allCliente);
        setDescuento(allCliente.descuento);
        setSelectedColaborador(allCliente.vendedor[0]._id);
        setSelectedColaboradorName(
          allCliente.vendedor[0].nombre +
            " " +
            allCliente.vendedor[0].apellido
        );
        setSelectedColaboradorTelefono(allCliente.vendedor[0].telefono);
        setDepartamentoCliente(allCliente.departamentosClientes[0]._id);
        setClienteNombre(allCliente.codigo + " " + allCliente.nombre_comercial);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function calculaImpuesto(e) {
    setSelectedImpuesto(e);
    if (e != 0) {
      setIva((e * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (e * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(comisionTDC) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionTDC(e) {
    setComisionTDC(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(e) +
        parseFloat(comisionFlete)
    );
  }

  function jalaComisionFlete(e) {
    setComisionFlete(e);
    if (selectedImpuesto != 0) {
      setIva((selectedImpuesto * subTotal) / 100);
    } else {
      setIva(0);
    }
    setTotalGeneral(
      (selectedImpuesto * subTotal) / 100 +
        subTotal -
        parseFloat(descuentoNumero) +
        parseFloat(comisionTDC) +
        parseFloat(e)
    );
  }

  function autorizaCambio() {
    if (password == randomCambiaPrecio) {
      setCambiarPrecio(true);
      toggleCambiaPrecio();
      Swal.fire("Good job!", "Se autorizo con exito", "success");
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La contraseña es incorrecta!",
      });
    }
  }

  function solicitarAut() {
    setMailAutEnviado(true);

    axios.post(
      `${URL_WHATSAPP}SendMsg`,
      {
        number: process.env.REACT_APP_TELEFONO_SUPER_ADMIN,
        message: `Hola,
El Vendedor ${user.nombre} ${user.apellido} esta solicitando un cambio de precio.
Si lo autorizas proporciona la clave:
${randomCambiaPrecio}`,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    );

    axios
      .post(
        URL_SEND_MAIL,
        {
          subject: "Cambio de Precio",
          email: process.env.REACT_APP_EMAIL_SUPER_ADMIN,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
       <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
       <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
       <tbody>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
       <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>El vendedor ${user.nombre} ${user.apellido} esta solicitando un cambio de precio, si lo autorizas proporciona la clave ${randomCambiaPrecio}</tr>
       </tbody>
       <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
       <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
       </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const hasNegativeValue = (inventarios, inputField) => {
    return inventarios.some(
      (a) => inputField.articulos == a.idArticulo && a.disponible < 0
    );
  };

  function PDFOC(numero) {
    let totalKG = inputFields.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);

    let importes = inputFields.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);

    let TC = inputFields.map((c) => parseFloat(c.cajas));
    let TTC = TC.reduce((t, total, index) => t + total, 0);

    let iva_tabla = (TG * selectedImpuesto) / 100;

    let temp1 = (TG * descuento) / 100;

    const data = inputFields
      .sort((a, b) => (a.articulos > b.articulos ? 1 : -1))
      .map((a) => {
        if (a.cantidad > 0) {
          return [
            a.articulosNombre,
            a.observacionesArticulo,
            new Intl.NumberFormat("en-US").format(a.cajas),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.precio),
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(a.total),
          ];
        }
      });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });

    const doc = new jsPDF({ compressPdf: true });
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 8, 5, 18, 5);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 10, 11, 15, 15);

    doc.setFontSize(12);
    const pageWidth = doc.internal.pageSize.getWidth(); // Obtén el ancho de la página
    const text = `Telefonos Oficina: ${process.env.REACT_APP_TELEFONO_INSTITUCION} - ${process.env.REACT_APP_NOMBRE_ADMIN}: ${process.env.REACT_APP_TELEFONO_SUPER_ADMIN}`;
    const textWidth = doc.getTextWidth(text); // Calcula el ancho del texto
    const xPosition = (pageWidth - textWidth) / 2; // Calcula la posición X para centrar el texto
    
    doc.text(text, xPosition, 13); // Dibuja el texto centrado en la posición Y deseada
    
    doc.setFontSize(16);
    doc.text(`Pedido: ${numero}`, 35, 20);
    
    doc.setFontSize(13);
    if (!vistaCliente) {
      doc.text(`Cliente: ${value.nombre_comercial}`, 10, 32);
      doc.text(`Telefono: ${value.telefono}`, 10, 37);
    } else {
      doc.text(`Cliente: ${nombre_comercial}`, 10, 32);
      doc.text(`Telefono: ${telefono}`, 10, 37);
    }
    doc.text(`Vendedor: ${selectedColaboradorName}`, 10, 42);
    doc.text(`Telefono Vendedor: ${selectedColaboradorTelefono}`, 10, 47);


    if (!vistaAnticipo) {
      doc.autoTable({
        head: [
          [
            "Articulo",
            "Observaciones",
            "Cajas",
            "Cant.",
            // "Surt.",
            "Precio",
            "Total",
          ],
        ],
        body: dataPDFLimpia,
        styles: {
          fontSize: 10,
        },
        startY: 50,

        margin: { left: 10, right: 10 },
        foot: [
          [
            "",
            "",
            new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
            new Intl.NumberFormat("en-US").format(TK) + "Piezas",
            "Sub Total",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(TG),
          ],
          [
            "",
            "",
            "",
            "",
            `IVA`,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(iva_tabla),
          ],
          [
            "",
            "",
            "",
            "",
            "Comision TDC",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(comisionTDC),
          ],
          [
            "",
            "",
            "",
            "",
            "Flete",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(comisionFlete),
          ],
          [
            "",
            "",
            "",
            "",
            `Desc ${descuento}%`,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(-temp1),
          ],
          [
            "",
            "",
            "",
            "",
            "Total",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(total_general),
          ],
        ],
        showFoot: "lastPage",
      });
    } else {
      doc.autoTable({
        head: [
          [
            "Articulo",
            "Observaciones",
            "Cajas",
            "Cant.",
            // "Surt.",
            "Precio",
            "Total",
          ],
        ],
        body: dataPDFLimpia,
        styles: {
          fontSize: 10,
        },
        startY: 50,

        margin: { left: 10, right: 10 },
        foot: [
          [
            "",
            "",
            new Intl.NumberFormat("en-US").format(TTC.toFixed(2)) + " Cajas",
            new Intl.NumberFormat("en-US").format(TK) + "Piezas",
            "Sub Total",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(TG),
          ],
          [
            "",
            "",
            "",
            "",
            `IVA`,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(iva_tabla),
          ],
          [
            "",
            "",
            "",
            "",
            "Comision TDC",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(comisionTDC),
          ],
          [
            "",
            "",
            "",
            "",
            "Flete",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(comisionFlete),
          ],
          [
            "",
            "",
            "",
            "",
            `Desc ${descuento}%`,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(-temp1),
          ],
          [
            "",
            "",
            "",
            "",
            `Anticipo`,
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(importe),
          ],
          [
            "",
            "",
            "",
            "",
            "Total",
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 2,
            }).format(total_general - importe),
          ],
        ],
        showFoot: "lastPage",
      });
    }

    doc.setFontSize(10);
    var lines = doc.splitTextToSize(
      `Observaciones ${observaciones} Anticipo ${ new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
      }).format(importe)}`,
      200
    );
    doc.text(10, doc.autoTable.previous.finalY + 10, lines);

    doc.save(`Pedido-${numero}.pdf`);
    doc.autoPrint();
    window.open(doc.output("bloburl"), "_blank");
  }

  const handleSearchClientes = async (searchText) => {
    try {
      const response = await axios.get(`${URL_CLIENTES}Search/${searchText}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("app_token")}`, // Agregar el token al header
        },
      });
      // Verificar que response.data sea un arreglo
      setClientes(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error buscando clientes", error);
    }
  };

  console.log("hola",value);

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Pedido</h3>
          {/* <Form > */}

          <Row>
            <Col>
              <Input
                type="checkbox"
                onChange={(event) => {
                  setVistaCliente(event.currentTarget.checked);
                  // setAlertasCliente("Si")
                }}
                checked={vistaCliente}
              />
              <h4 className="subMenuTitulo">Deseas Dar de alta al Cliente?</h4>
            </Col>
          </Row>

          {vistaCliente ? (
            <>
              <Row>
                <Col md={3}>
                  <Label>Nombre Comercial</Label>
                  <Input
                    type="text"
                    style={{ backgroundColor: "yellow" }}
                    placeholder="Nombre Comercial"
                    value={nombre_comercial}
                    required
                    onChange={(e) => {
                      setNombreComercial(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label>Razon Social</Label>
                  <Input
                    type="text"
                    placeholder="Razon Social"
                    value={razon_social}
                    required
                    onChange={(e) => {
                      setRazonSocial(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label>RFC</Label>
                  <Input
                    type="text"
                    placeholder="RFC"
                    value={RFC}
                    required
                    onChange={(e) => {
                      setRFC(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Paqueteria</Label>
                  <Input
                    className="col-sm-12"
                    style={{ backgroundColor: "yellow" }}
                    type="select"
                    value={selectedPaqueteria}
                    required
                    onChange={(e) => {
                      setSelectedPaqueteria(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona </option>
                    {paqueterias
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Label>Calle</Label>
                  <Input
                    type="text"
                    placeholder="Calle"
                    value={calle}
                    required
                    onChange={(e) => {
                      setCalle(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>No. Ext</Label>
                  <Input
                    type="text"
                    placeholder="No. Ext"
                    value={numero_ext}
                    required
                    onChange={(e) => {
                      setNumeroExt(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>No. Int</Label>
                  <Input
                    type="text"
                    placeholder="No. Int"
                    value={numero_int}
                    required
                    onChange={(e) => {
                      setNumeroInt(e.target.value);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <Label>Pais</Label>
                  <Input
                    type="text"
                    placeholder="Pais"
                    value={pais}
                    required
                    onChange={(e) => {
                      setPais(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Label>Estado</Label>
                  <Input
                    type="text"
                    placeholder="Estado"
                    value={estado}
                    required
                    onChange={(e) => {
                      setEstado(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label>Ciudad</Label>
                  <Input
                    type="text"
                    placeholder="Ciudad"
                    value={ciudad}
                    required
                    onChange={(e) => {
                      setCiudad(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label>Delegacion</Label>
                  <Input
                    type="text"
                    placeholder="Delegacion o Municipio"
                    value={delegacion}
                    required
                    onChange={(e) => {
                      setDelegacion(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label>Colonia</Label>
                  <Input
                    type="text"
                    placeholder="Colonia"
                    value={colonia}
                    required
                    onChange={(e) => {
                      setColonia(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Label>CP</Label>
                  <Input
                    type="text"
                    placeholder="CP"
                    value={cp}
                    required
                    onChange={(e) => {
                      setCP(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label>Telefono</Label>
                  <Input
                    type="text"
                    style={{ backgroundColor: "yellow" }}
                    placeholder="Telefono"
                    value={telefono}
                    required
                    onChange={(e) => {
                      setTelefono(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label>Email</Label>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    required
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Vendedor</Label>
                  <Input
                    style={{ backgroundColor: "yellow" }}
                    className="col-sm-12"
                    type="select"
                    value={selectedVendedorInput}
                    required
                    onChange={(e) => {
                      setSelectedVendedorInput(e.target.value);
                      setSelectedVendedor(e.target.value.split(",")[0]);
                      setSelectedColaborador(e.target.value.split(",")[0]);
                      setSelectedColaboradorName(
                        e.target.value.split(",")[1]
                      );

                      colaboradores.map((c) => {
                        if (c._id == e.target.value.split(",")[0]) {
                          setSelectedColaboradorTelefono(
                            c.telefono
                          );
                        }
                      });
                    }}
                  >
                    <option value="0">Selecciona un Vendedor</option>
                    {colaboradores
                      .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      .map((a) => {
                        return (
                          <option value={[a._id, a.nombre + " " + a.apellido]}>
                            {a.nombre} {a.apellido}
                          </option>
                        );
                      })}
                  </Input>
                </Col>
              </Row>

              <Row>
                <Col md={3}>
                  <Label>Forma de Pago</Label>
                  <Input
                    type="select"
                    placeholder="Forma de Pago"
                    value={forma_pago}
                    required
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="0">Forma de Pago</option>
                    <option value="Contado">Contado</option>
                    <option value="Credito">Credito</option>
                  </Input>
                </Col>

                <Col md={3}>
                  <Label>Plazo</Label>
                  <Input
                    type="select"
                    placeholder="Plazo"
                    value={plazo}
                    required
                    onChange={(e) => {
                      setPlazo(e.target.value);
                    }}
                  >
                    <option value="">Plazo</option>
                    <option value="0">0</option>
                    <option value="30">30</option>
                    <option value="60">60</option>
                    <option value="90">90</option>
                    <option value="120">120</option>
                    <option value="150">150</option>
                    <option value="180">180</option>
                  </Input>
                </Col>

                <Col md={3}>
                  <Label>Reg Fiscal</Label>
                  <Input
                    style={{ backgroundColor: "yellow" }}
                    type="select"
                    placeholder="RegFiscal"
                    value={regFiscal}
                    required
                    onChange={(e) => {
                      setRegFiscal(e.target.value);
                    }}
                  >
                    <option value="">Reg Fiscal</option>
                    <option value="0">0</option>
                    <option value="601">
                      601 General de Ley Personas Morales
                    </option>
                    <option value="603">
                      603 Personas Morales con Fines no Lucrativos
                    </option>
                    <option value="605">
                      605 Sueldos y Salarios e Ingresos Asimilados al Salario
                    </option>
                    <option value="608">608 Demas Ingresos</option>
                    <option value="612">
                      612 Personas Fisicas con Actividad Empresarial
                    </option>
                    <option value="616">616 Sin Obligacion Fiscal</option>
                    <option value="621">621 Incorporacion Fiscal</option>
                    <option value="625">
                      625 Reg. de las act. emp. cpn ingresos a traves de plat.
                      tec.
                    </option>
                    <option value="626">
                      626 Regimen simplificado de Confianza
                    </option>
                  </Input>
                </Col>
                <Col md={3}>
                  <Label className="mr-sm-2">Grupo</Label>
                  <Input
                    className="col-sm-12"
                    style={{ backgroundColor: "yellow" }}
                    type="select"
                    value={selectedGrupo}
                    required
                    onChange={(e) => {
                      setSelectedGrupo(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona una Grupo</option>
                    {grupos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Label className="mr-sm-2">Departamento</Label>
                  <Input
                    className="col-sm-12"
                    style={{ backgroundColor: "yellow" }}
                    type="select"
                    value={selectedDepartamento}
                    required
                    onChange={(e) => {
                      setSelectedDepartamento(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona </option>
                    {departamentos
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        return <option value={a._id}>{a.name}</option>;
                      })}
                  </Input>
                </Col>

                <Col md={3}>
                  <Label className="mr-sm-2">Almacen</Label>
                  <Input
                    className="col-sm-12"
                    style={{ backgroundColor: "yellow" }}
                    type="select"
                    value={selectedAreaCliente}
                    required
                    onChange={(e) => {
                      setSelectedAreaCliente(e.target.value);
                    }}
                  >
                    <option value="0">Selecciona</option>
                    {areas
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((a) => {
                        if (
                          a._id != AREAS_ELI &&
                          a._id != AREAS_MAGALI &&
                          a._id != AREAS_ROLANDO
                        ) {
                          return <option value={a._id}>{a.name}</option>;
                        }
                      })}
                  </Input>
                </Col>

                <Col md={3}>
                  <Label for="exampleEmail" className="mr-sm-2">
                    Observaciones
                  </Label>
                  <Input
                    type="text"
                    placeholder="Observaciones"
                    value={observacionesCliente}
                    required
                    onChange={(e) => {
                      setObservacionesCliente(e.target.value);
                    }}
                  />
                </Col>
                <Col md={3}>
                  <Label>Descuento</Label>
                  <Input
                    type="number"
                    placeholder="Descuento"
                    value={descuentoCliente}
                    required
                    onChange={(e) => {
                      setDescuentoCliente(e.target.value);
                    }}
                  />
                </Col>
              </Row>
              <br />
            </>
          ) : undefined}
          <Row>
            <Col md={2}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                disabled
              />
            </Col>
            <Col md={2}>
              <Label>Surtir de Almacen</Label>
              <Input
                type="select"
                value={selectedArea}
                onChange={(e) => {
                  setSelectedArea(e.target.value);
                }}
              >
                <option value="">Selecciona un almacen</option>
                {areas
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((a) => {
                    if (
                      a._id != AREAS_ELI &&
                      a._id != AREAS_MAGALI &&
                      a._id != AREAS_ROLANDO
                    ) {
                      return <option value={a._id}>{a.name}</option>;
                    }
                  })}
              </Input>
            </Col>
            {!vistaCliente ? (
              <Col md={3}>
                <Label className="mr-sm-2">Buscar</Label>
                <Autocomplete
                  size="small"
                  value={value}
                  onInputChange={(event, newInputValue) => {
                    handleSearchClientes(newInputValue); // Hacer una nueva búsqueda en cada cambio
                  }}
                  onChange={(event, selectedCliente) => {
                    if (selectedCliente) {
                      jalaCliente(selectedCliente);
                    }
                  }}
                  options={options2.sort(
                    (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                  )}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.junta}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Escribe las primeras letras"
                      variant="outlined"
                    />
                  )}
                  renderOption={(option) => (
                    <React.Fragment>
                      {option.codigo} {option.nombre_comercial}
                    </React.Fragment>
                  )}
                />
              </Col>
            ) : undefined}
          </Row>
          <Row>
            <Col md={4}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Descuento %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={descuento}
                onChange={(e) => {
                  calculaDescuento(e.target.value);
                }}
              />
            </Col>

            <Col md={1}>
              <Label>Impuestos %</Label>
              <Input
                type="number"
                min="0"
                step="any"
                value={selectedImpuesto}
                onChange={(e) => {
                  calculaImpuesto(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />

          {/* Tabla Articulos */}

          {/* <Form className={classes.root}> */}
          <Table borderless>
            <thead>
              <tr>
                <th style={{ maxWidth: "120px" }}>Codigo Qr</th>
                <th>Buscador</th>
                <th>Articulo</th>
                <th style={{ maxWidth: "60px" }}>Disponible</th>
                <th>Observaciones</th>
                <th style={{ maxWidth: "60px" }}>Cajas</th>
                <th style={{ maxWidth: "60px" }}>Pzs x Caja</th>
                <th style={{ maxWidth: "60px" }}>Cantidad</th>
                <th style={{ maxWidth: "60px" }}>Precio Original</th>
                <th style={{ maxWidth: "60px" }}>Precio</th>
                <th style={{ maxWidth: "80px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              {inputFields.map((inputField) => (
                <tr>
                  <td style={{ maxWidth: "120px" }}>
                    <Input
                      name="articulos"
                      type="string"
                      value={inputField.articulos}
                      onChange={(event) => {
                        BuscaCodigo(inputField.id, event);
                      }}
                    ></Input>
                  </td>
                  <td>
                    <Autocomplete
                      size="small"
                      value={inputField.articulosNombre}
                      onChange={(event, selectedArticulo) => {
                        BuscaArticulo(inputField.id, selectedArticulo);
                      }}
                      options={options.sort(
                        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
                      )}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.junta}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Selecciona"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option) => (
                        <React.Fragment>{option.codigo}</React.Fragment>
                      )}
                    />
                  </td>
                  <td>
                    <Input
                      name="articulo"
                      type="string"
                      placeholder="Articulo"
                      value={inputField.articulosNombre}
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      type="select"
                      disabled
                      style={{
                        backgroundColor: hasNegativeValue(
                          inventarios,
                          inputField
                        )
                          ? "red"
                          : "initial",
                      }}
                    >
                      {inventarios.map((a) => {
                        if (inputField.articulos == a.idArticulo) {
                          return (
                            <option key={a.idArticulo}>{a.disponible}</option>
                          );
                        }
                        return null;
                      })}
                    </Input>
                  </td>

                  <td>
                    <Input
                      name="observaciones"
                      type="text"
                      placeholder="observaciones"
                      value={inputField.observacionesArticulo}
                      required
                      onChange={(event) =>
                        handleChangeInputObservaciones(inputField.id, event)
                      }
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      name="cajas"
                      type="string"
                      value={inputField.cajas}
                      onChange={(event) =>
                        handleChangeInputCajas(inputField.id, event)
                      }
                    ></Input>
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      name="piezasPorCaja"
                      type="number"
                      min="0"
                      step="any"
                      value={inputField.piezasPorCaja}
                      required
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      name="cantidad"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Cantidad"
                      value={inputField.cantidad}
                      required
                      onChange={(event) =>
                        handleChangeInputCantidad(inputField.id, event)
                      }
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      // bsSize="sm"
                      name="precioOriginal"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="PrecioOriginal"
                      value={inputField.precioOriginal}
                      disabled
                    />
                  </td>
                  <td style={{ maxWidth: "60px" }}>
                    <Input
                      style={
                        inputField.precioOriginal - 50 > inputField.precio
                          ? { backgroundColor: "#ed8c8c" }
                          : null
                      }
                      // bsSize="sm"
                      name="precio"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="Precio"
                      value={inputField.precio}
                      required
                      onChange={(event) =>
                        handleChangeInput(inputField.id, event)
                      }
                    />
                  </td>
                  <td style={{ maxWidth: "80px" }}>
                    <Input
                      name="total"
                      type="number"
                      min="0"
                      step="any"
                      placeholder="total"
                      value={inputField.total}
                      disabled
                    />
                  </td>
                  <td>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() => handleRemoveFields(inputField.id)}
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* </Form> */}
          {/* Termina Tabla Articulos */}

          <br />
          <Row>
            <Col md={4}></Col>
            <Col md={1}>
              <h4 align="right">Total Piezas:</h4>
            </Col>
            <Col md={1}>
              <h4 align="right">
                {new Intl.NumberFormat("en-US").format(total_cantidad)}
              </h4>
            </Col>
            <Col md={1}></Col>
            <Col md={1}>
              <h4 align="right">Subtotal:</h4>
            </Col>
            <Col md={1}>
              <h4 align="right">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(subTotal)}
              </h4>
            </Col>
          </Row>

          <Row>
            <Col md={7}></Col>
            <Col md={1}>
              <h4 align="right">IVA:</h4>
            </Col>
            <Col md={1}>
              <h4 align="right">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(iva)}
              </h4>
            </Col>
          </Row>

          <Row>
            <Col md={7}></Col>
            <Col md={1}>
              <h4 align="right">Total:</h4>
            </Col>
            <Col md={1}>
              <h4 align="right">
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 2,
                }).format(total_general)}
              </h4>
            </Col>
          </Row>

          <br />
          <Row>
            <Col md={3}>
              <Input
                type="checkbox"
                onChange={(event) =>
                  setVistaAnticipo(event.currentTarget.checked)
                }
                checked={vistaAnticipo}
              />
              <h4 className="subMenuTitulo">Deseas dar de alta un Anticipo?</h4>
            </Col>
            {/* </Row> */}
            {vistaAnticipo ? (
              <>
                {/* <Row> */}
                <Col md={2}>
                  <Label className="mr-sm-2">Forma de Pago</Label>
                  <Input
                    type="select"
                    value={formaPago}
                    onChange={(e) => {
                      setFormaPago(e.target.value);
                    }}
                  >
                    <option value="">Selecciona una Forma de Pago</option>
                    <option value="Efectivo">Efectivo</option>
                    <option value="Transfer">Transfer</option>
                    <option value="TDC">TDC</option>
                  </Input>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Banco</Label>
                  <Input
                    type="select"
                    value={selectedBanco}
                    onChange={(e) => {
                      setSelectedBanco(e.target.value);
                    }}
                  >
                    <option value="">Selecciona un Banco</option>
                    {bancos
                      .sort((a, b) => (a.banco > b.banco ? 1 : -1))
                      .map((a) => {
                        if(a.is_active == "Si"){
                        return (
                          <option value={a._id}>
                            {a.banco} {a.cuenta}
                          </option>
                        );}
                      })}
                  </Input>
                </Col>
                <Col sm={2}>
                  <Label className="mr-sm-2">Importe</Label>
                  <CurrencyInput
                    intlConfig={{ locale: "en-US", currency: "USD" }}
                    prefix="$"
                    defaultValue={0}
                    decimalsLimit={2}
                    value={importe}
                    className={`form-control`}
                    onValueChange={(value, name) => setImporte(value)}
                  />
                </Col>
              </>
            ) : undefined}
          </Row>
          <Row>
            <Col md={1}>
              {validaBoton ? (
                <Button
                  type="submit"
                  className="btn btn-success"
                  tabindex="-1"
                  onClick={savePedido}
                >
                  Guardar
                </Button>
              ) : (
                <Button type="submit" className="btn btn-success" disabled>
                  Guardar
                </Button>
              )}
            </Col>
            <Col md={1}>
              <Button
                href="/ListadoPedidos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
          </Row>
          {/* </Form> */}
          <br />
          <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
              <h4>
                {" "}
                Este proceso puede tardar varios segundos.
                <br />
                Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
                <Spinner
                  style={{
                    width: "50px",
                    height: "50px",
                    color: "#232B41",
                  }}
                />
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={modalCambiaPrecio}
            toggle={toggleCambiaPrecio}
          >
            <ModalHeader toggle={toggleCambiaPrecio}>
              <h4>Autorizar Precio</h4>
            </ModalHeader>
            <ModalBody>
              {mailAutEnviado == false ? (
                <Col>
                  <Button
                    type="submit"
                    className="btn btn-success"
                    onClick={(e) => solicitarAut()}
                  >
                    Solicitar Autorizacion
                  </Button>
                </Col>
              ) : (
                <>
                  <Input
                    bsSize="sm"
                    type="password"
                    placeholder="Password"
                    value={password}
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <br />
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => autorizaCambio()}
                      >
                        Autorizar
                      </Button>
                    </Col>

                    <Col>
                      <Button
                        type="submit"
                        className="btn btn-success"
                        onClick={(e) => solicitarAut()}
                      >
                        Reenviar Solicitud
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default PedidosExpoCreate;
